"use client";
import React, { useRef, useEffect } from "react";
import Image from "next/image";
import HeroHeader from "@/components/HeroHeader";
import { motion } from "framer-motion";

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

const themes = [
  {
    title: "content1",
    theme: {
      background: "#fff",
    },
  },
  {
    title: "content2",
    theme: {
      background: "#dad8d6",
    },
  },
];

export default function Home() {
  const GroupRef = useRef([]);

  const onScroll = (e: any) => {
    if (GroupRef.current[0] == null) {
      document.body.style.backgroundColor = "white";
    } else if (GroupRef.current !== null) {
      const styles: any = GroupRef.current
        .map((item: any, index) => {
          const rect = item.getBoundingClientRect();
          return { item, rect };
        })
        .find((item) => item.rect.bottom >= window.innerHeight * 0.5);

      document.body.style.backgroundColor = `${styles.item.dataset.bgcolor}`;
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll, false);
  }, []);

  return (
    <div className="overflow-hidden min-h-screen w-full h-full relative flex flex-col items-center  gap-5">
      <div className="w-full h-full relative">
        <HeroHeader />
      </div>

      <section
        id="hero"
        className="w-full min-h-screen h-full max-w-7xl  p-4 lg:p-10 flex items-center mt-20 lg:mt-14 2xl:mt-0 bg-white"
      >
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-5 w-full h-full items-center justify-items-center ">
          <div className="w-full h-[65vh] lg:h-[70vh]  overflow-hidden relative flex p-2 lg:p-4 border border-[#83603f] ">
            <motion.div
              initial={{ scale: 0.7, opacity: 0 }}
              animate={{ scale: 1, opacity: 1 }}
              transition={{ type: "spring", duration: 3, delay: 0 }}
              className="w-full h-full relative overflow-hidden"
            >
              <Image
                src="/assets/shutterstock_158902820_BW-thumb.webp"
                alt="home-1"
                sizes="100vw"
                width={0}
                height={0}
                className="hover:scale-105 duration-500 transition-all object-cover object-center w-full h-full"
              />
            </motion.div>
          </div>

          <motion.div
            initial={{ y: "200%" }}
            animate={{
              y: 0,
              transition: { ease: [0.455, 0.03, 0.515, 0.955], duration: 1 },
            }}
            className="w-full h-full flex justify-center items-center"
          >
            <div className="text-center text-[#7b7c80] grid justify-items-center">
              <p className="font-serif italic">Where Your</p>
              <h1 className="font-bold text-4xl font-serif text-[#82603f] mb-5">
                HEART <br />
                BELONGS
              </h1>
              <hr
                className="w-28 mb-5"
                style={{ borderTop: "2px solid #82603f " }}
              />
              <p className="text-sm max-w-[400px] w-full font-light whitespace-pre-line">
                Welcome to{" "}
                <span className="text-[#82603f] font-medium">
                  BAAN SINDHORN
                </span>
                . Our residence offers a new urban living phenomenon that every
                family is looking for. This project is situated in a prime
                location right in heart of Bangkok, with only 32 exquisite
                units, 2-bedroom or Penthouse, on offer.
              </p>
            </div>
          </motion.div>
        </div>
      </section>

      <section
        id="section1"
        className="w-full min-h-[45vh] bg-fixed  lg:min-h-screen h-full relative overflow-hidden bg-[url('/assets/home-1-thumb.webp')]  lg:bg-[url('/assets/home-1-1-thumb.webp')] grayscale bg-cover bg-left lg:bg-center bg-no-repeat"
      ></section>

      <section
        id="content1"
        className="w-full min-h-[180vh] h-full   flex justify-center relative"
      >
        <div className="w-full h-full max-w-7xl  p-4 lg:p-10 flex items-center absolute py-14">
          <div className="grid grid-cols-1 w-full h-full">
            <div className="grid grid-cols-1 lg:grid-cols-2  h-full lg:h-[85vh]">
              <div className=" h-full flex justify-center items-center">
                <div
                  data-aos="fade-zoom-in"
                  data-aos-easing="ease-in-back"
                  data-aos-delay="200"
                  className="text-center text-[#7b7c80] grid justify-items-center"
                >
                  <p className="font-serif italic">Exterior and Facilites</p>
                  <h1 className="font-bold  text-3xl lg:text-4xl font-serif text-[#82603f] mb-5">
                    A SENSE OF <br />
                    BELONGING
                  </h1>
                  <hr
                    className="w-28 mb-5"
                    style={{ borderTop: "2px solid #82603f " }}
                  />
                  <p className="text-sm max-w-[400px] whitespace-pre-line w-full font-light">
                    Sit back and relax.{" "}
                    <span className="text-[#82603f] font-medium">
                      BAAN SINDHORN
                    </span>{" "}
                    offers close family bond in an ultimate lifestyle. The
                    luxury makes striking feeling of a sumptuous yet cozy flair
                    every family has always promised themselves.
                  </p>
                </div>
              </div>
              <div className="h-[50vh] lg:h-full bg-[#83603f] p-10 lg:p-20">
                <div className="h-full border p-2 lg:p-4">
                  <div className="relative h-full w-full overflow-hidden">
                    <Image
                      data-aos="zoom-in-down"
                      src="/assets/shutterstock_277961978_BW-thumb.webp"
                      alt="shutterstock_277961978_BW"
                      sizes="100vw"
                      width={0}
                      height={0}
                      className="grayscale hover:!scale-110 duration-500 w-full h-full object-cover object-center transition-all "
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2  h-full lg:h-[85vh]">
              <div
                data-aos="fade-zoom-in"
                data-aos-easing="ease-in-back"
                data-aos-delay="200"
                className="h-full flex justify-center items-center lg:col-start-2"
              >
                <div className="text-center text-[#7b7c80] grid justify-items-center">
                  <p className="font-serif italic">Enchanting Rooms</p>
                  <h1 className="font-bold text-3xl lg:text-4xl font-serif text-[#82603f] mb-5 max-w-sm w-full">
                    REFLECTING
                    <br />
                    AN APPRECIATION OF FAMILY BOND
                  </h1>
                  <hr
                    className="w-28 mb-5"
                    style={{ borderTop: "2px solid #82603f " }}
                  />
                  <p className="text-sm max-w-[395px] whitespace-pre-line w-full font-light ">
                    Simple but elegant, every corner is designed to be both a
                    place for you to relax with family and sophisticated
                    functional part of working life, while also allowing privacy
                    when desired.{" "}
                    <span className="text-[#82603f] font-medium">
                      BAAN SINDHORN
                    </span>{" "}
                    represents an eternal family bond as much as it embodies
                    luxury living.
                  </p>
                </div>
              </div>

              <div className="h-[50vh] lg:h-full bg-[#83603f] p-10 lg:p-20 lg:row-start-1">
                <div className="h-full border p-2 lg:p-4">
                  <div className="relative h-full overflow-hidden">
                    <Image
                      data-aos="zoom-in-down"
                      src="/assets/shutterstock_632932427_BW-thumb.webp"
                      alt="shutterstock_632932427_BW"
                      sizes="100vw"
                      width={0}
                      height={0}
                      className="grayscale hover:!scale-110 duration-500 w-full h-full object-cover object-left transition-all "
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {GroupRef &&
        themes.map(
          (
            item: { theme: { background: any }; title: string },
            index: number
          ) => (
            <div
              key={index}
              //@ts-ignore
              ref={(e) => (GroupRef.current[index] = e)}
              data-bgcolor={item.theme.background}
              className="w-full h-full"
            >
              <section
                className={classNames(
                  item.title == "content2"
                    ? "w-full min-h-screen h-full flex flex-col items-center -translate-y-2  "
                    : "hidden opacity-0"
                )}
              >
                <div className="w-full h-full max-w-7xl  p-4 lg:p-10  mb-10">
                  <div
                    data-aos="fade-up"
                    data-aos-easing="ease-in-back"
                    data-aos-delay="200"
                    className="text-center text-[#7b7c80] grid justify-items-center"
                  >
                    <p className="font-serif italic">Leafly, Rooftop Garden</p>
                    <h1 className="font-bold text-4xl font-serif text-[#82603f] mb-5  max-w-xs w-full ">
                      A GREEN LIFE ON TOP OF <br /> THE WORLD
                    </h1>

                    <hr
                      className="w-28 mb-5"
                      style={{ borderTop: "2px solid #82603f " }}
                    />
                    <p className="text-sm max-w-[350px] whitespace-pre-line font-light ">
                      Let’s make the most hurried day as slow as <br />a visit
                      to a park. Here, our o2-filled area teams up with the
                      exotic heaven that is hard to beat.
                    </p>
                  </div>
                </div>

                <div className="w-full  h-[75vh] relative">
                  <Image
                    data-aos="zoom-out-up"
                    src="/assets/home-image-thumb.webp"
                    alt="home-image"
                    sizes="100vw"
                    width={0}
                    height={0}
                    className="w-full h-full object-cover object-center"
                  />
                </div>
              </section>
            </div>
          )
        )}
    </div>
  );
}
