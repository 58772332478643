"use client";

import React, { FC, useEffect, useState, useRef } from "react";
import Image from "next/image";
import { Link, animateScroll as scroll, scroller } from "react-scroll";
import { motion, useAnimation, AnimatePresence } from "framer-motion";
interface Props {}

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

const HeroHeader: FC<Props> = (): JSX.Element => {
  const [isVisible, setIsVisible] = useState<boolean>(true);
  const [isVisibleBG, setIsVisibleBG] = useState<boolean>(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsVisible(false);
    }, 15000);

    const timerBG = setTimeout(() => {
      setIsVisibleBG(false);
    }, 17000);

    return () => {
      clearTimeout(timer);
      clearTimeout(timerBG);
    };
  }, []);
  return (
    <>
      <div className="relative h-[75vh] lg:h-screen flex flex-col items-center overflow-hidden">
        <video
          className="absolute top-0 left-0 w-full h-full object-cover !object-top"
          preload="none"
          autoPlay
          playsInline
          loop
          muted
        >
          <source
            src="https://eiffelbakery.com/video/BAAN%20SINDHORN_Final.mp4"
            type="video/mp4"
          />
        </video>

        <div
          className={classNames(
            isVisibleBG
              ? "h-full bg-black/30 top-0"
              : "bottom-0 h-1/3 bg-gradient-to-t from-black/30",
            "absolute  left-0 w-full transition-all duration-500 "
          )}
        />

        <div className="absolute flex-1 inset-0 flex flex-col items-center justify-center px-4 md:px-6 lg:px-8 xl:px-10">
          <AnimatePresence>
            {isVisible && (
              <motion.h1
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 1, delay: 3, ease: "backInOut" }}
                className="text-3xl lg:text-5xl  text-white text-center  drop-shadow-md font-serif capitalize"
              >
                Experience an urban living phenomenon
              </motion.h1>
            )}
          </AnimatePresence>

          <AnimatePresence>
            {isVisible && (
              <motion.p
                initial={{ y: 20, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ opacity: 0, y: 50 }}
                transition={{ duration: 1, delay: 3.5, ease: "backInOut" }}
                className="text-sm lg:text-lg text-white/75 mt-2 lg:mt-4 text-center max-w-screen-lg"
              >
                Luxury 2-bedroom and penthouse units in the heart of Bangkok,
                offering family-focused designs and a rooftop garden oasis.
              </motion.p>
            )}
          </AnimatePresence>
        </div>

        <div className="hidden lg:flex absolute w-28 h-fit bottom-[10%] !z-10 cursor-pointer hover:scale-110 transition duration-300 justify-center items-center ">
          <Link
            href="#"
            role="link"
            activeClass="active"
            to="hero"
            spy={true}
            smooth={true}
            offset={-80}
            duration={1000}
          >
            <button
              type="button"
              className="arrow-exp text-white  h-full outline-none text-base "
            >
              SCROLL
            </button>
          </Link>
        </div>
      </div>
    </>
  );
};

export default HeroHeader;
